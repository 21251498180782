import xHttp from '@utils/xHttp';
import { getBaseApiUrl } from '@utils/config';

let priorGetHomesCall;

export const getHomes = (
  minSquareFeet,
  maxSquareFeet,
  baths,
  beds,
  distance,
  latitude,
  longitude,
  minPrice,
  maxPrice,
  isMultiSection,
  isPremierFinancingEligible,
  sortMethodId,
  numberOfItemsPerPage,
  startingIndex,
  modelsTypeId,
  features = ''
) => {
  let url = `${getBaseApiUrl()}performancemodels?minSquarefeet=${minSquareFeet}&maxSquareFeet=${maxSquareFeet}&baths=${baths}&beds=${beds}&distance=${distance}&latitude=${latitude}&longitude=${longitude}&minPrice=${minPrice}&maxPrice=${maxPrice}&isMultiSection=${isMultiSection}&isPremierFinancingEligible=${isPremierFinancingEligible}&sortMethodId=${sortMethodId}&numberOfItemsPerPage=${numberOfItemsPerPage}&startingIndex=${startingIndex}&modelsTypeId=${modelsTypeId}`;

  if (features && features.length > 0) {
    url += `&features=${features}`;
  }

  if (priorGetHomesCall != null) {
    priorGetHomesCall.cancel();
  }

  priorGetHomesCall = xHttp.get(url);

  priorGetHomesCall.then((result) => {
    priorGetHomesCall = null;
    return result;
  });

  return priorGetHomesCall;
};

export const getModelByModelNumber = (
  modelNumber,
  latitude,
  longitude,
  distance
) => {
  if (!modelNumber) {
    throw new Error('modelNumber is required.');
  }
  const locParams =
    latitude && longitude
      ? `&latitude=${latitude}&longitude=${longitude}&distance=${distance}`
      : null;
  let url = `${getBaseApiUrl()}model?modelNumber=${modelNumber}`;
  if (locParams) {
    url += locParams;
  }
  return xHttp.get(url);
};

export const getModelsByModelNumbers = (
  modelNumbers,
  latitude,
  longitude,
  searchRadiusInMiles
) => {
  let url = `${getBaseApiUrl()}modeldetails?${modelNumbers
    .map((m) => `modelNumbers=${m}`)
    .join('&')}`;

  if (latitude && longitude && searchRadiusInMiles) {
    url += `&latitude=${latitude}&longitude=${longitude}&searchRadiusInMiles=${searchRadiusInMiles}`;
  }
  return xHttp.get(url);
};

export const getRecentlyViewedHomes = (
  latitude,
  longitude,
  distance,
  visitorToken
) => {
  const url = `${getBaseApiUrl()}recent?latitude=${latitude}&longitude=${longitude}&distance=${distance}&visitorToken=${visitorToken}`;
  return xHttp.get(url);
};

export const getAutoCompletedModels = (searchTerm) => {
  const url = `${getBaseApiUrl()}autocompletedmodels?searchTerm=${searchTerm}`;
  return xHttp.get(url);
};

export const insertModelIntoRecentlyViewed = (
  modelId,
  visitorToken,
  latitude = null,
  longitude = null,
  distance = null
) => {
  if (!modelId) {
    throw new Error('modelId is required');
  }

  let url = `${getBaseApiUrl()}recent/model`;
  if (latitude && longitude && distance) {
    url =
      url + `?latitude=${latitude}&longitude=${longitude}&distance=${distance}`;
  }
  const data = {
    modelId,
  };

  if (visitorToken) {
    data.visitorToken = visitorToken;
  }
  // if (analyticsId){
  //     data.googleAnalyticsId = analyticsId;
  // }
  return xHttp.post(url, data);
};

export const sendModelImpressions = (modelIds) => {
  const url = `${getBaseApiUrl()}impressions/models`;
  return xHttp.post(url, { modelIds: modelIds });
};

// Get detail information for a model using either a ModelNumber or SerialNumber.
// The SerialNumber and isOnLand=true is used for homes with land.
export const getModelDetails = (
  modelNumber,
  isOnLand = false,
  latitude,
  longitude,
  distance
) => {
  if (!modelNumber) {
    throw new Error('modelNumber or serialNumber is required.');
  }

  let url = `${getBaseApiUrl()}modeldetails/${modelNumber}?isOnland=${isOnLand}`;
  if (latitude && longitude && distance) {
    url =
      url + `&latitude=${latitude}&longitude=${longitude}&distance=${distance}`;
  }
  return xHttp.get(url);
};
